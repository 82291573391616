<template>
  <div class="head-container">
    <!-- 搜索 -->
     <el-input v-model="query.userInfo" clearable placeholder="用户ID/用户昵称" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
      &nbsp;&nbsp;
     <div class="filter-item">
      注册时间:
      <el-date-picker style="width: 150px;" v-model="query.beginDate" type="date" placeholder="开始日期"></el-date-picker>
      至
      <el-date-picker style="width: 150px;" v-model="query.endDate" type="date" placeholder="结束日期"></el-date-picker>
    </div>
    &nbsp;&nbsp;
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  props: {
    query: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$parent.page = 0
      this.$parent.init()
    }
  }
}
</script>
