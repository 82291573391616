<template>
  <div class="app-container">
    <eHeader :query="query"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="用户ID" width="80"/>
      <el-table-column prop="userName" label="用户名称"/>
      <el-table-column prop="createTime" label="注册时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="电话号码"/>
      <el-table-column prop="email" label="邮箱"/>
      <el-table-column prop="platformCount" label="开通平台数">
        <template slot-scope="scope">
          <span v-if="scope.row.platformCount==0">0</span>
          <span v-else class="link-sum" @click="openPlatform(scope.row.id)">{{scope.row.platformCount}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="customerCount" label="名下客户数">
        <template slot-scope="scope">
          <span v-if="scope.row.customerCount==0">0</span>
          <span v-else class="link-sum" @click="viewSubCust(scope.row.id)">{{scope.row.customerCount}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="copyerCount" label="名下跟随者账号数">
        <template slot-scope="scope">
          <span v-if="scope.row.copyerCount==0">0</span>
          <span v-else class="link-sum" @click="viewSubMt4(scope.row.id,2)">{{scope.row.copyerCount}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="traderCount" label="名下交易员账号数">
        <template slot-scope="scope">
          <span v-if="scope.row.traderCount==0">0</span>
          <span v-else class="link-sum" @click="viewSubMt4(scope.row.id)">{{scope.row.traderCount}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="partnerStatus" label="状态"/>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>

      <el-dialog title="平台信息" :visible.sync="dialogFormVisible" append-to-body width="70%">
        <el-table :data="flatformdata" stripe style="width: 100%">
          <el-table-column prop="id" label="ID" width="60"></el-table-column>
          <el-table-column prop="siteName" label="所属社区"></el-table-column>
          <el-table-column prop="serverName" label="合作平台服务器"></el-table-column>
           <el-table-column prop="platformLogin" label="合作平台MT4账号"/>
          <el-table-column prop="partnerUserName" label="合作伙伴昵称"></el-table-column>
          <el-table-column prop="platformIbId" label="平台代理号"></el-table-column>
          <el-table-column prop="platformRegLink" label="合作平台注册链接" width="300"/>
        </el-table>
      </el-dialog>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import { getSrCommunityPartnerRelations } from '@/api/community/partnerRelation'
import eHeader from '@/components/community/partnermanage/header'
export default {
  name:'partnermanage',
  components: { eHeader },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      sup_this: this,
      dialogFormVisible:false,
      flatformdata:[],
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = 'community/crm/getCommunityPartnerInfo'
      const sort = 'ib.id,desc'
      this.params = { page: this.page, size: this.size, sort: sort }
      const query = this.query
      const userInfo = query.userInfo;
      const beginDate = query.beginDate;
      const endDate = query.endDate;
      if (userInfo !== "" && userInfo !== null) {
        this.params["userInfo"] = userInfo;
      }
      if (beginDate !== "" && beginDate !== null) {
        this.params["beginDate"] = beginDate;
      }
      if (endDate !== "" && endDate !== null) {
        this.params["endDate"] = endDate;
      }
      return true
    },
    openPlatform(id){
      this.dialogFormVisible=true;
      const sort = 't1.id,asc'
      this.formdata = { page: 0, size: 10000,sort: sort,userInfo: id}
      getSrCommunityPartnerRelations(this.formdata).then(res => {
        this.flatformdata=res.content
      }).catch(err => {
      })
    },
    viewSubCust(data){
      this.$router.push({
        path: '/commu/cusmgr/cuslist',
        query: {belongUserId: data}
      })
    },
    savePartnerUserId(partnerUserId){
      this.$store.commit('savePartnerUserId',partnerUserId);
    },
    viewSubMt4(partnerUserId){
      this.savePartnerUserId(partnerUserId);
      this.$router.push({
        path: '/commu/cusmgr/accmgr',
      })
    },
  }
}
</script>

<style scoped>
.link-sum{
  color:blue;
  text-decoration:underline;
  cursor:pointer;
}
.link-sum:hover {
  text-decoration:underline;
   color:red;
}
</style>
